<template>
  <section>
    <h1>Mentions légales :</h1>

    <h2>1. Ce site est édité par :</h2>

    <h3>Les Hôpitaux Universitaires de Strasbourg</h3>

    <address>
      1 place de l'hôpital<br/>
      BP 426<br/>
      67091 Strasbourg cedex<br/>
    </address>

    <div><a href="tel:+33388116768">03 88 11 67 68</a><br/></div>
    <div><a href="mailto:courrierdg@chru-strasbourg.fr">courrierdg@chru-strasbourg.fr</a><br/></div>
    <div>N° SIREN : 266700574 - <a href="http://finess.sante.gouv.fr">http://finess.sante.gouv.fr</a></div>

    <h3>Directeur de la publication</h3>
    <div>Le Directeur Général des Hôpitaux Universitaires de Strasbourg</div>

    <h2>2. Ce site est actuellement hébergé par :</h2>
    <address>
      L’Université de Strasbourg<br/>
      4 rue Blaise Pascal<br/>
      CS 90032<br/>
      F-67081 Strasbourg cedex <br/>
    </address>

    <div>Téléphone (standard général) : <a href="tel:+33368580000">03 68 85 00 00</a></div>
    <div>N° SIREN : 130005457</div>

    <p>L’Université de Strasbourg est actuellement l’hébergeur du présent site internet et a participé à son
      développement avec le CHU de Strasbourg. Ce site internet sera directement hébergé par le CHU de Strasbourg à
      compter de l’année 2023/2024.</p>

    <h3>Quel est l’objectif poursuivi par ce site internet ?</h3>
    <p>Dans une démarche de recherche clinique, et le cas échéant de soins, ce site internet permet aux patients de
      renseigner un agenda du sommeil et des évaluations cognitives et cliniques notamment sous forme de
      questionnaire.</p>
    <p>En ayant été sélectionné et informé par votre médecin à participer à une recherche associant l’utilisation de ce
      site internet, vous avez accepté que le CHU de Strasbourg puisse utiliser ce site internet et toutes ses
      fonctionnalités afin d’effectuer notamment un suivi de votre sommeil à des fins de recherche, et le cas échéant,
      de permettre d’orienter votre suivi médical.</p>

    <h3>La protection de vos données personnelles :</h3>
    <p>Le CHU de Strasbourg et ses prestataires s’engagent à assurer la plus grande protection de vos données
      personnelles et ainsi à avoir mis en œuvre toutes les mesures de protection, aussi bien techniques
      qu’organisationnelles, adaptées à la nature des données traitées, aux activités et aux moyens du CHU de Strasbourg
      afin de garantir la confidentialité et la sécurité des données, notamment pour éviter tout accès non autorisé.</p>
    <p>Le CHU de Strasbourg fait ses meilleurs efforts pour adopter une démarche active dans la vérification du bon
      respect de la réglementation sur la protection des données personnelles par ses sous-traitants et ce, afin
      notamment de vérifier qu’ils présentent des garanties appropriées pour assurer la sécurité et la confidentialité
      des données personnelles. Ces mesures tendent à empêcher la destruction, la perte, l’intrusion ou l’accès non
      autorisé à ces données, de manière accidentelle ou malveillante.</p>
    <p class="no__bottom__margin">Si vous souhaitez utiliser ce site internet, notamment à des fins de participation à
      une recherche médicale, vous
      voudrez bien trouver toutes dans la notice d’information et le formulaire de consentement qui vous a été remis,
      les informations nécessaires concernant notamment :
    </p>
    <ul>
      <li>La base légale et la finalité de la recherche associée à ce site internet ;</li>
      <li>Les données amenées à être traitées et les modalités de traitement ;</li>
      <li>La sécurité de vos données ;</li>
      <li>Vos droits sur vos données personnelles.</li>
    </ul>
    <p>Au besoin, vous voudrez bien trouver les informations de contact du délégué à la protection des données du CHU de
      Strasbourg :</p>
    <div class="address">
      <address>
        <h3>Hôpitaux Universitaires de Strasbourg</h3>
        <div>Délégué à la Protection des Données Pôle Finances et Système d'Information</div>
        1 place de l’Hôpital<br/>
        BP 426<br/>
        67091 Strasbourg Cedex
      </address>

      <div>
        <a href="mailto:dpd@chru-strasbourg.fr">dpd@chru-strasbourg.fr</a>
      </div>
    </div>
    <p>Conformément à la réglementation, toute personne concernée par un traitement de données personnelles, dispose du
      droit d’introduire une réclamation auprès d’une autorité de contrôle compétente en vertu de la réglementation en
      vigueur.</p>
    <p>Le CHU de Strasbourg se réserve le droit de modifier, sans préavis et à tout moment, ces mentions légales pour
      prendre en compte les réglementaires ou tout changement technique lié au site internet ou lié à l’organisation du
      CHU de Strasbourg.</p>
    <p>Si vous estimez, après nous avoir contactés, que vos droits Informatique et Libertés ne sont pas respectés ou que
      le dispositif de contrôle d’accès n’est pas conforme aux règles de protection des données, vous pouvez adresser
      une réclamation à la CNIL (cf. <a href="https://www.cnil.fr">https://www.cnil.fr</a>).</p>

    <h3>Comment puis-je exercer mes droits sur mes données personnelles ?</h3>
    <p>Vos droits s’exercent auprès du médecin généraliste qui vous suit dans le cadre de la recherche, qui connaît
      votre identité. Vous pouvez à tout moment demander des informations à votre médecin généraliste au cours ou à
      l’issue de la recherche. En cas de retrait de consentement, les données recueillies dans le cadre de votre
      participation seront utilisées. Si vous souhaitez que les données recueillies ne soient pas utilisées, il faudra
      en faire la demande par courrier au médecin qui vous suit afin que vos données soient détruites. Ce dernier
      transmettra à son tour une demande de destruction des données associées à votre code alphanumérique au promoteur
      de l’étude qui supprimera vos données de la base. En fin de recherche, vous serez informé(e) des résultats globaux
      sur simple demande auprès de votre médecin généraliste, si vous le souhaitez.</p>

    <h3>Propriété intellectuelle :</h3>
    <p>L'ensemble de ce site relève de la législation française et internationale sur le droit d'auteur et la propriété
      intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables et les
      représentations iconographiques. Le CHU de Strasbourg est propriétaire des droits de propriété intellectuelle sur
      tous les éléments textuels, graphiques, sonores, audiovisuels et logiciels composant ce site.</p>
    <p>La reproduction de tout ou partie de ce site sur un support électronique ou autre quel qu'il soit est
      formellement interdite sauf autorisation expresse du CHU de Strasbourg.</p>
    <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site,
      quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable du CHU de
      Strasbourg. Toute exploitation des éléments de ce site sans l’autorisation du CHU de Strasbourg sera considérée
      comme constitutive d’une contrefaçon et pourra être poursuivie, conformément aux dispositions des articles L.335-2
      et suivants du code de la propriété intellectuelle. Il en va de même de l’utilisation du logo ou du nom du CHU de
      Strasbourg ainsi que de tout élément figuratif ou semi-figuratif de ce site internet susceptible de constituer une
      marque déposée.</p>
    <p>Pour toutes utilisations, veuillez nous consulter au préalable.</p>

    <h3>Vos obligations :</h3>
    <p class="no__bottom__margin">Sans préjudice des autres obligations prévues dans ces mentions légales, vous vous
      engagez à respecter les conditions suivantes :</p>
    <ul>
      <li>L’utilisateur s’engage, dans son usage du site, à respecter les lois et règlement en vigueur et à ne pas
        porter atteinte aux droits de tiers ou à l’ordre public ;
      </li>
      <li>L’utilisateur s’engage à ne pas renseigner des données au nom ou pour le compte d’une tierce personne. Ainsi,
        l’utilisateur s’engage à conserver confidentielles ses informations de connexion au site internet et à
        n’utiliser le site internet qu’à titre personnel ;
      </li>
      <li>L’utilisateur garantit la véracité et l’exactitude des données d’identification et/ou de connexion ainsi que
        des données renseignées lors de sa participation, via le présent site internet, à toute opération de recherche
        ou de soin associée aux fonctionnalités de ce site ;
      </li>
      <li>L’utilisateur est informé et accepte que sa participation via ce site internet nécessite qu’il soit connecté à
        internet et que la qualité du service dépend directement de cette connexion, dont l’opérateur internet est seul
        responsable ;
      </li>
      <li>L’utilisateur est informé et accepte que sa participation entraîne une collecte des données qu’il communique à
        des fins de recherche notamment. Le cas échéant, ces données sont collectées selon les modalités présentées dans
        la notice d’information associée et le formulaire de consentement auquel l’utilisateur a consenti avant de
        débuter toute opération de recherche associée à ce site internet.
      </li>
    </ul>
    <p>Il est rappelé qu'il appartient à chaque utilisateur de prendre toutes les mesures appropriées de façon à
      protéger ses propres données ou logiciels de la contamination d'éventuels virus circulant sur Internet.</p>

    <h3>Responsabilités :</h3>
    <p>Le CHU de Strasbourg ne saurait être tenu responsable de dommages matériels liés à l’utilisation du site. De
      plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus
      et avec un navigateur de dernière génération mis-à-jour.</p>
    <p>Le CHU de Strasbourg, en lien avec ses prestataires de service, s’efforce de permettre un accès constant à ce
      site internet, sauf en cas de force majeure, et sous réserve des éventuelles pannes ou interventions de
      maintenance nécessaires au bon fonctionnement du site. La responsabilité du CHU de Strasbourg ne saurait être
      engagée en cas d’impossibilité d’accès à ce site. L’utilisateur reconnaît que le CHU de Strasbourg ne supporte
      qu’une obligation de moyen, à l’exclusion de toute obligation de résultat.</p>
    <p>En tout état de cause, la responsabilité susceptible d’être encourue par le CHU de Strasbourg est expressément
      limitée aux seuls dommages directs avérés subis par l’utilisateur et ayant un lien de causalité avec le CHU de
      Strasbourg. De même, le CHU de Strasbourg ne saurait être tenu responsable des éléments en dehors de son contrôle
      et des dommages qui pourraient éventuellement être subis par l’environnement technique de l’utilisateur, et
      notamment les ordinateurs, logiciels, tablettes, équipements réseaux (modems, téléphones…) de l’utilisateur ou
      tout autre matériel utilisé pour accéder et/ou utiliser le site internet ou les informations disponibles sur ce
      site internet.</p>

    <h3>Hyperliens et autres sites :</h3>
    <p>Dans le cas où des hyperliens seraient insérés sur ce site internet, le CHU de Strasbourg ne pourra en aucun cas
      être tenu responsable de la disponibilité technique du site internet exploité par un tiers et vers lequel
      l’utilisateur accéderait, par l’intermédiaire du présent site. Ces liens ne constituent, en aucun cas, une
      approbation ou un partenariat avec les sites internet visés. Dès lors, le CHU de Strasbourg ne saurait être tenu
      responsable du contenu, des produits, publicités ou tout autre élément ou service présenté sur ces sites.</p>
    <p>Le CHU de Strasbourg n’autorise en aucun cas un quelconque site Internet tiers ou tout autre support à le citer
      ou à mettre en place un lien hypertexte pointant vers ce site internet sans son accord préalable.</p>

    <h3>Interdiction de tout accès non autorisé ou frauduleux :</h3>
    <p>Il est rappelé que le fait d’accéder ou de se maintenir frauduleusement sans autorisation dans un système
      informatique, d’entraver ou de fausser le fonctionnement d’un tel système, d’introduire ou de modifier
      frauduleusement des données dans un système informatique constitue un délit passible de sanctions pénales,
      conformément à l’article L.323-1 du Code pénal.</p>
  </section>
</template>

<script>
export default {
  name: 'Legals',
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/style";
@import "../../assets/style/backgroundPage";

section {
  padding: 15px;

  h1 {
    text-decoration: underline;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 28px;
    margin-left: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  h3 {
    margin-top: 20px;
  }

  div, address, p, li {
    font-size: 20px;
  }

  p {
    text-indent: 30px;
    margin-top: 10px;
  }

  p.no__bottom__margin {
    margin-bottom: 0;
  }

  .address {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    address {
      border: 1px solid #bfbfbf;
      text-align: center;

      h3, div {
        font-style: normal;
        text-align: center;
      }
    }

    > div {
      border: 1px solid #bfbfbf;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
